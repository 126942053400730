const IconFilterTheme = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		{...props}
	>
		<path
			d="M21.6629 19.571L20.2359 20.999L13.7939 14.557L15.2239 13.129L21.6629 19.571ZM13.7829 3.00098C11.2029 3.00098 8.62291 3.98098 6.64291 5.95098L6.63291 5.96098C2.68291 9.91098 2.68291 16.321 6.63291 20.271L20.9329 5.96098C18.9629 3.99098 16.3729 3.00098 13.7829 3.00098ZM6.80291 17.271C6.06291 16.031 5.66291 14.611 5.66291 13.121C5.66291 12.191 5.82291 11.301 6.12291 10.451C6.31291 12.361 7.01291 14.241 8.19291 15.891L6.80291 17.271ZM9.64291 14.431C8.29291 12.381 7.78291 9.93098 8.26291 7.60098C8.84291 7.48098 9.42291 7.42098 10.0129 7.42098C11.8129 7.42098 13.5629 7.97098 15.0929 8.98098L9.64291 14.431ZM11.1129 5.46098C11.9629 5.16098 12.8529 5.00098 13.7829 5.00098C15.2729 5.00098 16.6929 5.40098 17.9329 6.14098L16.5429 7.53098C14.8929 6.35098 13.0229 5.65098 11.1129 5.46098Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconFilterTheme;
