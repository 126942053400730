import { useState, useEffect } from "react";
import { RESOLUTION } from "app/constants";
import config from "app/pages/.styles/export.scss";

const getInitResolution = () => {
	const width = window.innerWidth;
	const isDefault = width <= parseFloat(config.sm);
	const isSmall = width >= parseFloat(config.sm);
	const isMedium = width >= parseFloat(config.md);
	const isLarge = width >= parseFloat(config.lg);
	let clientResolution = RESOLUTION.UNKNOWN;

	if (isDefault === false && isSmall === true && isMedium === true && isLarge === true) {
		clientResolution = RESOLUTION.XLARGE;
	} else if (isDefault === false && isSmall === true && isMedium === true && isLarge === false) {
		clientResolution = RESOLUTION.LARGE;
	} else if (isDefault === false && isSmall === true && isMedium === false && isLarge === false) {
		clientResolution = RESOLUTION.MEDIUM;
	} else if (isDefault === true && isSmall === false && isMedium === false && isLarge === false) {
		clientResolution = RESOLUTION.SMALL;
	}
	return clientResolution;
};

const useResponsiveState = resolution => {
	const [responsiveState, setResponsiveState] = useState(resolution);

	const mediaQuerySmall = window.matchMedia(`(max-width: ${config.sm})`);
	const mediaQueryMedium = window.matchMedia(
		`(min-width: ${config.sm}) and (max-width: ${config.md}`
	);
	const mediaQueryLarge = window.matchMedia(
		`(min-width: ${config.md}) and (max-width: ${config.lg})`
	);
	const mediaQueryXLarge = window.matchMedia(`(min-width: ${config.lg})`);

	useEffect(() => {
		setResponsiveState(getInitResolution());

		if (mediaQuerySmall?.addEventListener) {
			mediaQuerySmall.addEventListener("change", event => {
				if (event.matches) {
					setResponsiveState(RESOLUTION.SMALL);
				}
			});
		}

		if (mediaQueryMedium?.addEventListener) {
			mediaQueryMedium.addEventListener("change", event => {
				if (event.matches) {
					setResponsiveState(RESOLUTION.MEDIUM);
				}
			});
		}

		if (mediaQueryLarge?.addEventListener) {
			mediaQueryLarge.addEventListener("change", event => {
				if (event.matches) {
					setResponsiveState(RESOLUTION.LARGE);
				}
			});
		}

		if (mediaQueryXLarge?.addEventListener) {
			mediaQueryXLarge.addEventListener("change", event => {
				if (event.matches) {
					setResponsiveState(RESOLUTION.XLARGE);
				}
			});
		}

		return () => {
			if (mediaQuerySmall?.removeEventListener) {
				mediaQuerySmall.removeEventListener("change", () => {});
			}
			if (mediaQueryMedium?.removeEventListener) {
				mediaQueryMedium.removeEventListener("change", () => {});
			}
			if (mediaQueryLarge?.removeEventListener) {
				mediaQueryLarge.removeEventListener("change", () => {});
			}
			if (mediaQueryXLarge?.removeEventListener) {
				mediaQueryXLarge.removeEventListener("change", () => {});
			}
		};
	}, []);
	return responsiveState;
};

export default useResponsiveState;
