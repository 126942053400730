const IconLogoAtoutFrance = props => (
	<svg viewBox="0 0 132.89 75.69" {...props}>
		<path fill="none" d="M0 0H132.89V75.69H0z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.46 33.1L10.36 35.97 18.36 35.97 17.27 33.1 11.46 33.1z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.28 0L5.28 35.97 6.23 35.97 12.14 21.79 16.6 21.79 22.49 35.97 23.44 35.97 23.44 0 5.28 0z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M12.41 30.63L16.35 30.63 14.39 25.45 12.41 30.63zM56.88 48.52L54.91 43.32 52.92 48.52 56.88 48.52z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M57.13 39.64L63.06 53.89 58.91 53.89 57.81 51 51.98 51 50.86 53.89 46.71 53.89 52.65 39.64 45.81 39.64 45.81 75.69 63.96 75.69 63.96 39.64 57.13 39.64z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M51.19 43.24h-4.9V54h-4V43.24h-4.9V39.8H51.19zM77.39 46.87c0 4.34-3 7.28-7.59 7.28-5.27 0-7.58-3.68-7.58-7.28 0-4.63 3.3-7.27 7.58-7.27C74.85 39.6 77.39 42.94 77.39 46.87zm-4.11 0a3.48 3.48 0 1 0-7 0 3.48 3.48 0 1 0 7 0zM104.48 47.62c0 4.32-3 6.54-7.05 6.54S90.36 52 90.36 47.62V39.8h4v6.72c0 2.43.9 4.08 3 4.08s3-1.5 3-4.06V39.8h4zM131 43.24h-4.9V54h-4V43.24h-4.9V39.8H131zM19.41 66.12H13.83v5.77H9.78V57.64h9.63V60.5H13.83v2.76h5.58zM41.89 71.89H37.54l-1.06-2.53a10.75 10.75 0 0 0-1-2 2.08 2.08 0 0 0-2-1h-.46v5.56H28.88V57.64h6.9a4.44 4.44 0 0 1 4.74 4.42 3.69 3.69 0 0 1-2.2 3.56c1.06.65 1.43 1.35 2.22 3.18zm-5.51-9.75c0-1.27-.7-1.77-2.6-1.77h-.85v3.43h.87C35.65 63.81 36.38 63.53 36.38 62.14zM90.5 71.89H86.9l-7-7.94v7.94h-4V57.64h3.6l7 7.9v-7.9h4zM111.73 70.82a10.62 10.62 0 0 1-5.31 1.25c-5 0-7.6-3.25-7.6-7.32 0-5.15 3.74-7.31 7.66-7.31a15.09 15.09 0 0 1 5.19 1.08v3.79a14 14 0 0 0-2-1.1 5.57 5.57 0 0 0-2.29-.44 4.15 4.15 0 0 0-4.45 4 4 4 0 0 0 4.23 3.94 7.34 7.34 0 0 0 3.32-.81 12.69 12.69 0 0 0 1.23-.73zM131 71.89H120.82V57.64h10V60.5h-5.92v2.61h5.64V66h-5.64v3H131z"
			transform="translate(-3.34 -18)"
		/>
	</svg>
);

export default IconLogoAtoutFrance;
