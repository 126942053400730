const ChevronRight = props => (
	<svg
		{...props}
		width="16"
		height="17"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="chevron_right">
			<path
				id="Vector"
				d="M6.46979 4.5L5.52979 5.44L8.58312 8.5L5.52979 11.56L6.46979 12.5L10.4698 8.5L6.46979 4.5Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default ChevronRight;
