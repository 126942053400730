import { useEffect } from "react";

export default shouldBlock => {
	useEffect(() => {
		if (shouldBlock) {
			// hack [1] empecher le scroll sur la page derriere la modal sur ios safari mobile
			document.querySelector("html").style.overflowY = "hidden";
			document.querySelector("html").style.position = "fixed";
			document.querySelector("html").style.top = "0";
			document.querySelector("html").style.bottom = "0";
			document.querySelector("html").style.left = "0";
			document.querySelector("html").style.right = "0";
		} else {
			// on reautorise le scroll sur ios safari suite au hack [1]
			document.querySelector("html").style.overflowY = "auto";
			document.querySelector("html").style.position = "static";
		}

		// permet de ne pas bloquer la page si une exception survient dans le composant parent
		return () => {
			document.querySelector("html").style.overflowY = "auto";
			document.querySelector("html").style.position = "static";
		};
	}, [shouldBlock]);
};
