const IconFilterCalendar = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		{...props}
	>
		<g id="calendar_month">
			<path
				id="Vector"
				d="M19.3333 4H18.3333V2H16.3333V4H8.33325V2H6.33325V4H5.33325C4.22325 4 3.34325 4.9 3.34325 6L3.33325 20C3.33325 21.1 4.22325 22 5.33325 22H19.3333C20.4333 22 21.3333 21.1 21.3333 20V6C21.3333 4.9 20.4333 4 19.3333 4ZM19.3333 20H5.33325V10H19.3333V20ZM19.3333 8H5.33325V6H19.3333V8ZM9.33325 14H7.33325V12H9.33325V14ZM13.3333 14H11.3333V12H13.3333V14ZM17.3333 14H15.3333V12H17.3333V14ZM9.33325 18H7.33325V16H9.33325V18ZM13.3333 18H11.3333V16H13.3333V18ZM17.3333 18H15.3333V16H17.3333V18Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default IconFilterCalendar;
