const IconPhone = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		width="30"
		height="30"
		viewBox="0 0 240 240"
		fill="none"
	>
		<path
			fill="currentColor"
			d="M205.5,98.9c-.4-46.8-38.5-84.7-85.4-84.7S35.2,52.1,34.8,98.8c-11.6,3.3-19.8,13.8-19.8,25.9v18.2c0,12.2,8.5,22.9,20.4,26.1l17,4.4c.9.3,2.2.3,3.1.3,3.1,0,6-.9,8.2-2.8,3.1-2.5,5-6.3,5-10.4v-53.4c0-4.1-1.9-7.8-5-10.4-3.1-2.5-7.2-3.5-11.3-2.5l-3,.8c2.4-37.1,33.4-66.4,70.8-66.4s68.5,29.6,70.8,66.5l-3.3-.9c-4.1-.9-8.2,0-11.3,2.5-3.1,2.5-5,6.3-5,10.4v53.4c0,4.1,1.9,7.8,5,10.4,2.5,1.9,5.3,2.8,8.2,2.8s2.2-.3,3.1-.3l3.1-.8v.2c0,6.9-5.7,12.2-12.2,12.2h-23.2c-3-8.6-11.1-14.8-20.8-14.8h-29.2c-12.2,0-22,9.7-22,22s9.7,22,22,22h29.2c9.6,0,17.6-6,20.7-14.4h23.3c15.1,0,27-12.2,27-27v-4.1c11.4-3.4,19.5-13.9,19.5-25.8v-18.2c0-11.9-8-22.3-19.5-25.8ZM53.9,109.1v49.5l-15.1-3.8c-5.3-1.6-9.1-6.3-9.1-11.9v-18.2c0-5.7,3.8-10.7,9.1-11.9l15.1-3.7ZM134.6,199.4h-29.2c-4.1,0-7.2-3.1-7.2-7.2s3.1-7.2,7.2-7.2h29.2c4.1,0,7.2,3.1,7.2,7.2s-3.1,7.2-7.2,7.2ZM210.3,142.9c0,5.7-3.8,10.7-9.1,11.9l-15.1,3.8v-49.6l15.1,3.8c5.3,1.6,9.1,6.3,9.1,11.9v18.2Z"
		/>
	</svg>
);

export default IconPhone;
