const IconMenu = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22"
		height="20"
		viewBox="0 0 22 20"
		fill="none"
		{...props}
	>
		<path
			d="M0.333374 2.00002C0.333374 1.6464 0.47385 1.30726 0.723899 1.05721C0.973947 0.807163 1.31309 0.666687 1.66671 0.666687H20.3334C20.687 0.666687 21.0261 0.807163 21.2762 1.05721C21.5262 1.30726 21.6667 1.6464 21.6667 2.00002C21.6667 2.35364 21.5262 2.69278 21.2762 2.94283C21.0261 3.19288 20.687 3.33335 20.3334 3.33335H1.66671C1.31309 3.33335 0.973947 3.19288 0.723899 2.94283C0.47385 2.69278 0.333374 2.35364 0.333374 2.00002ZM0.333374 18C0.333374 17.6464 0.47385 17.3073 0.723899 17.0572C0.973947 16.8072 1.31309 16.6667 1.66671 16.6667H20.3334C20.687 16.6667 21.0261 16.8072 21.2762 17.0572C21.5262 17.3073 21.6667 17.6464 21.6667 18C21.6667 18.3536 21.5262 18.6928 21.2762 18.9428C21.0261 19.1929 20.687 19.3334 20.3334 19.3334H1.66671C1.31309 19.3334 0.973947 19.1929 0.723899 18.9428C0.47385 18.6928 0.333374 18.3536 0.333374 18ZM9.66671 8.66669C9.31308 8.66669 8.97395 8.80716 8.7239 9.05721C8.47385 9.30726 8.33337 9.6464 8.33337 10C8.33337 10.3536 8.47385 10.6928 8.7239 10.9428C8.97395 11.1929 9.31308 11.3334 9.66671 11.3334H20.3334C20.687 11.3334 21.0261 11.1929 21.2762 10.9428C21.5262 10.6928 21.6667 10.3536 21.6667 10C21.6667 9.6464 21.5262 9.30726 21.2762 9.05721C21.0261 8.80716 20.687 8.66669 20.3334 8.66669H9.66671Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconMenu;
