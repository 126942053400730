const IconError = props => (
	<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="warning_amber">
			<path
				id="Vector"
				d="M8 3.66248L13.02 12.3358H2.98L8 3.66248ZM1.82667 11.6691C1.31333 12.5558 1.95333 13.6691 2.98 13.6691H13.02C14.0467 13.6691 14.6867 12.5558 14.1733 11.6691L9.15333 2.99581C8.64 2.10914 7.36 2.10914 6.84667 2.99581L1.82667 11.6691ZM7.33333 7.00248V8.33581C7.33333 8.70248 7.63333 9.00248 8 9.00248C8.36667 9.00248 8.66667 8.70248 8.66667 8.33581V7.00248C8.66667 6.63581 8.36667 6.33581 8 6.33581C7.63333 6.33581 7.33333 6.63581 7.33333 7.00248ZM7.33333 10.3358H8.66667V11.6691H7.33333V10.3358Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default IconError;
