const IconLuggage = props => (
	<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viexbox="0 0 21 20" {...props}>
		<path
			d="M17.065 5.261h-2.941V3.067a1.694 1.694 0 0 0-.493-1.174c-.31-.312-.73-.49-1.168-.495H8.336a1.676 1.676 0 0 0-1.168.495c-.31.312-.487.733-.492 1.174v2.194H3.687a2.1 2.1 0 0 0-1.47.62c-.391.393-.613.924-.617 1.479v8.346a2.096 2.096 0 0 0 .607 1.488 2.074 2.074 0 0 0 1.48.61h.664v.383a.808.808 0 0 0 .497.75c.098.04.203.061.31.06a.769.769 0 0 0 .755-.495.78.78 0 0 0 .051-.316v-.381h8.871v.381a.806.806 0 0 0 .497.752c.098.04.204.06.31.06a.769.769 0 0 0 .755-.496.78.78 0 0 0 .051-.316v-.381h.664a2.066 2.066 0 0 0 1.48-.611 2.086 2.086 0 0 0 .608-1.488V7.36a2.2 2.2 0 0 0-.655-1.474 2.176 2.176 0 0 0-1.48-.625Zm-9.44-2.194a.735.735 0 0 1 .711-.715h4.127a.728.728 0 0 1 .712.715v2.194h-.902a.808.808 0 0 0-.496-.75.795.795 0 0 0-.31-.06H9.285a.795.795 0 0 0-.747.499.807.807 0 0 0-.06.311h-.901V3.067h.047Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconLuggage;
