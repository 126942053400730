const IconCrossWhite = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="transparent"
	>
		<path
			d="M0.5 20C0.5 30.7696 9.23045 39.5 20 39.5C30.7696 39.5 39.5 30.7696 39.5 20C39.5 9.23045 30.7696 0.5 20 0.5C9.23045 0.5 0.5 9.23045 0.5 20Z"
			stroke="white"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.1339 12.8661C26.6457 12.378 25.8543 12.378 25.3661 12.8661L20 18.2322L14.6339 12.8661C14.1457 12.378 13.3543 12.378 12.8661 12.8661C12.378 13.3543 12.378 14.1457 12.8661 14.6339L18.2322 20L12.8661 25.3661C12.378 25.8543 12.378 26.6457 12.8661 27.1339C13.3543 27.622 14.1457 27.622 14.6339 27.1339L20 21.7678L25.3661 27.1339C25.8543 27.622 26.6457 27.622 27.1339 27.1339C27.622 26.6457 27.622 25.8543 27.1339 25.3661L21.7678 20L27.1339 14.6339C27.622 14.1457 27.622 13.3543 27.1339 12.8661Z"
			fill="white"
		/>
	</svg>
);

export default IconCrossWhite;
