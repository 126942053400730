import { PureComponent } from "react";
import PropTypes from "prop-types";
import "./AdvancedSelect.scss";
import Select from "react-select";
import { messagePropType } from "app/utils/propTypes";

class AdvancedSelect extends PureComponent {
	render() {
		return (
			<Select
				className="advanced-select"
				classNamePrefix="advanced-select"
				styles={{
					dropdownIndicator: (base, state) => ({
						...base,
						transition: "all .2s ease",
						transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
					}),
					menuList: base => ({ ...base, maxHeight: "175px", padding: "0" }), // on limite volontairement pour afficher la moitié du 4ème pour suggérer la possibilité de scroller pour afficher la suite
					option: base => ({
						...base,
						border: "none",
						height: "50px",
						display: "flex",
						alignItems: "center",
					}),
				}}
				{...this.props}
			/>
		);
	}
}

AdvancedSelect.propTypes = {
	placeholder: messagePropType,
	value: PropTypes.any,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onMenuOpen: PropTypes.func,
	onMenuClose: PropTypes.func,
};

export default AdvancedSelect;
