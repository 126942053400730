const IconPaiement2x = props => (
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M32 12.1384C31.9996 10.1335 31.3185 8.1881 30.0682 6.6208C28.8179 5.05351 27.0725 3.95718 25.1178 3.51132C23.1631 3.06546 21.115 3.2965 19.3087 4.1666C17.5025 5.03671 16.0452 6.49432 15.1754 8.30075H1.58561C1.16547 8.30204 0.762917 8.46951 0.465837 8.76659C0.168757 9.06367 0.00128894 9.46623 0 9.88636L0 24.3803C0.00128894 24.8004 0.168757 25.203 0.465837 25.5C0.762917 25.7971 1.16547 25.9646 1.58561 25.9659H25.0547C25.4748 25.9646 25.8774 25.7971 26.1745 25.5C26.4715 25.203 26.639 24.8004 26.6403 24.3803V20.273C28.2322 19.5911 29.5888 18.4566 30.5416 17.0105C31.4944 15.5643 32.0015 13.8702 32 12.1384ZM14.557 10.045C14.3917 10.7359 14.3066 11.4435 14.3033 12.1539C14.3033 12.3601 14.3193 12.5502 14.3193 12.7408H1.74421V10.045H14.557ZM24.8961 24.2372H1.74421V15.9915H15.1754C15.8989 17.4926 17.0312 18.7591 18.4422 19.6455C19.8532 20.5319 21.4856 21.0022 23.1519 21.0025C23.7376 21.0009 24.3217 20.9425 24.8961 20.8279V24.2372ZM23.1519 19.5755C21.6825 19.5774 20.2455 19.1435 19.0227 18.3288C17.7998 17.514 16.8461 16.3548 16.2821 14.998C15.7181 13.6411 15.5691 12.1475 15.854 10.706C16.1389 9.26443 16.8449 7.93976 17.8827 6.89949C18.9204 5.85921 20.2434 5.15006 21.6843 4.86171C23.1251 4.57336 24.6191 4.71877 25.9773 5.27954C27.3355 5.84032 28.4969 6.79128 29.3146 8.01215C30.1323 9.23302 30.5696 10.669 30.5712 12.1384C30.5729 14.1082 29.7923 15.9982 28.401 17.3927C27.0098 18.7873 25.1218 19.5724 23.1519 19.5755Z"
			fill="currentColor"
		/>
		<path
			d="M20.5672 13.3277C21.2336 12.5822 21.9311 11.9163 21.9311 10.8225C21.9284 10.5763 21.8752 10.3334 21.7749 10.1086C21.6746 9.88382 21.5294 9.68196 21.3481 9.51546C21.1668 9.34895 20.9534 9.22135 20.7209 9.1405C20.4884 9.05965 20.2418 9.02728 19.9963 9.04537C19.0922 9.04537 18.4414 9.36258 17.8079 10.3298L18.6964 11.0273C18.7843 10.787 18.9379 10.5763 19.1398 10.4192C19.3417 10.2621 19.5837 10.1649 19.8382 10.1387C19.9374 10.1274 20.038 10.1384 20.1325 10.1708C20.227 10.2032 20.313 10.2563 20.3844 10.3262C20.4558 10.396 20.5108 10.4809 20.5452 10.5747C20.5797 10.6685 20.5928 10.7687 20.5837 10.8682C20.5837 11.6612 19.7276 12.4383 18.221 14.214L17.8247 14.6898V14.8662H22.1537V13.74H20.2034L20.5672 13.3277Z"
			fill="currentColor"
		/>
		<path
			d="M28.2258 9.17287H26.7033L25.8627 10.5057L25.6091 10.934L25.3714 10.5217L24.529 9.17287H22.991L24.7352 11.8052L22.7075 14.8658H24.2776L25.3243 13.1851L25.562 12.7728L25.7841 13.1691L26.8148 14.8658H28.3849L26.4185 11.9162L28.2258 9.17287Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPaiement2x;
