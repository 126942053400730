import { memo, useCallback, useEffect, useState } from "react";
import VideoPreviewPicture from "app/pages/.shared/Video/VideoPreviewPicture";
import Video from "app/pages/.shared/Video/Video";
import PropTypes from "prop-types";
import { resolutionPropType } from "app/utils/propTypes";

const previewContainerStyles = { height: "100%", width: "100%", cursor: "pointer" };

const VideoSwitchThumbnail = ({
	videoType,
	videoUrl,
	videoPreviewUrl,
	videoThumbnail,
	sizes = {},
	isActive,
	onVideoStart = () => {},
	resolution,
}) => {
	const [showVideo, setVideoVisibility] = useState(false);
	const onPreviewPictureClick = useCallback(() => {
		setVideoVisibility(true);
		onVideoStart();
	}, []);

	useEffect(() => {
		if (!isActive && showVideo) {
			setVideoVisibility(false);
		}
	}, [isActive, showVideo]);

	return showVideo ? (
		<Video autoplay={1} videoType={videoType} url={videoUrl} />
	) : (
		<div style={previewContainerStyles} onClick={onPreviewPictureClick}>
			<VideoPreviewPicture
				previewPicture={videoPreviewUrl}
				thumbnail={videoThumbnail}
				sizes={sizes}
				resolution={resolution}
			/>
		</div>
	);
};

VideoSwitchThumbnail.propTypes = {
	videoType: PropTypes.string,
	videoUrl: PropTypes.string,
	videoPreviewUrl: PropTypes.string,
	videoThumbnail: PropTypes.string,
	onVideoStart: PropTypes.func,
	sizes: PropTypes.object,
	isActive: PropTypes.bool,
	resolution: resolutionPropType,
};

export default memo(VideoSwitchThumbnail);
