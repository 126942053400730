import { memo } from "react";
import PropTypes from "prop-types";
import "./VideoPreviewPicture.scss";
import IconPlay from "app/pages/.shared/static/icons/IconPlay";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";

const IMG_SIZES = {
	small: { height: 550 },
	medium: { height: 550 },
	large: { height: 550 },
	xlarge: { height: 550 },
};

const CLOUDINARY_OPTIONS = ["c_fill", "g_center"];

const VideoPreviewPicture = ({ previewPicture, thumbnail, sizes = IMG_SIZES, resolution }) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const imageComponent = isMobile ? (
		<ProgressiveImg
			sizes={sizes}
			resolution={resolution}
			thumbnail={thumbnail}
			src={previewPicture}
			cloudinaryOptions={CLOUDINARY_OPTIONS}
		/>
	) : (
		<BackgroundProgressiveImg
			backgroundPosition="center"
			sizes={sizes}
			src={previewPicture}
			thumbnail={thumbnail}
			cloudinaryOptions={CLOUDINARY_OPTIONS}
		/>
	);

	return (
		previewPicture && (
			<div className="video-preview-picture">
				{imageComponent}
				{/* Le picto Play doit etre après BackgroundProgressiveImg sinon le picto disparait en fade puis rerapparait a chaque render */}
				<div className="video-preview-picture__play">
					<IconPlay height={50} width={50} />
				</div>
			</div>
		)
	);
};

VideoPreviewPicture.propTypes = {
	previewPicture: PropTypes.string,
	thumbnail: PropTypes.string,
	sizes: PropTypes.object,
	resolution: resolutionPropType,
};

export default memo(VideoPreviewPicture);
