import PropTypes from "prop-types";
import classNames from "classnames";
import { memo } from "react";
import "./Typography.scss";

export const TYPOGRAPHY_VARIANTS = {
	XL10: "xl10",
	XL9: "xl9",
	XL8: "xl8",
	XL7: "xl7",
	XL6: "xl6",
	XL5: "xl5",
	XL4: "xl4",
	XL3: "xl3",
	XL2: "xl2",
	XL: "xl",
	LARGE: "large",
	REGULAR: "regular",
	SMALL: "small",
	XSMALL: "xsmall",
	XXSMALL: "xxsmall",
};

const Typography = ({
	variant,
	variantMC,
	variantSM,
	variantMD,
	variantLG,
	isBold,
	isUnderline,
	component,
	children,
	className,
	ref,
	...props
}) => {
	const Component = component || "div";
	const typoClass = classNames(
		{
			[`typography--${variant}${isBold ? "-bold" : ""}`]: variant,
			[`typography--mc-${variantMC}${isBold ? "-bold" : ""}`]: variantMC,
			[`typography--sm-${variantSM}${isBold ? "-bold" : ""}`]: variantSM,
			[`typography--md-${variantMD}${isBold ? "-bold" : ""}`]: variantMD,
			[`typography--lg-${variantLG}${isBold ? "-bold" : ""}`]: variantLG,
			"typography--underline": isUnderline,
		},
		className
	);

	return (
		<Component className={typoClass} {...props} ref={ref}>
			{children}
		</Component>
	);
};

Typography.propTypes = {
	variant: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)),
	variantMC: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)),
	variantSM: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)),
	variantMD: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)),
	variantLG: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANTS)),
	isBold: PropTypes.bool,
	isUnderline: PropTypes.bool,
	ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	children: PropTypes.node,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	component: PropTypes.string,
};

export default memo(Typography);
