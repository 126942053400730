import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { scrollToElement } from "app/utils/scroller";
import { ALERT_TYPE } from "app/constants";
import { isEmpty } from "lodash";
import { clean } from "fast-clean";
import { deepMappingError, getFieldErrorNames } from "app/pages/.shared/form/formikUtils";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { useIntl } from "react-intl";
import usePrevious from "app/utils/hooks/usePrevious";
import uniq from "lodash/uniq";
import { alertTypeProptypes } from "app/utils/propTypes";

/**
 * Display formik errors list under a message in an alert message bloc
 * It must be placed inside Formik component.
 * @param message error message to display on top of the validation errors list. If message is not provided as prop, a default one is displayed
 * @param fieldsFilter array of field names. if fieldsFilter include the first errors of formik, we scroll to the first FormErrorMessages
 * @param disableErrorsDetails if true, list of validation errors is not display under the message
 * @param onError function called when getting error from form validation
 * @returns {JSX.Element|boolean}
 */
const FormErrorMessages = ({
	message,
	isSubmitting,
	errors = {},
	onError = () => {},
	fieldsFilter = [],
	disableErrorsDetails = false,
	submitCount,
	scrollInToView = true,
	alertType = ALERT_TYPE.ERROR,
}) => {
	const computedErrors = clean(errors); // clean object error or array error empty

	const intl = useIntl();

	if (!isEmpty(computedErrors) && computedErrors._error) {
		delete computedErrors._error;
	}

	const fieldErrorNames = getFieldErrorNames(computedErrors);

	const scrollable =
		scrollInToView &&
		fieldErrorNames[0] &&
		(fieldsFilter.length === 0 ||
			fieldsFilter.includes(fieldErrorNames[0]) ||
			fieldsFilter.find(fieldFilter => fieldErrorNames[0].includes(fieldFilter))); // pour le cas des FieldArray où fieldErrorNames[0] = childrenBirthdates.0 et fieldFilter = childrenBirthdates

	const showError =
		fieldsFilter.length > 0
			? fieldsFilter.some(field => computedErrors[field])
			: !isEmpty(computedErrors);

	const ref = useRef();

	let errorsList = [];

	if (!isEmpty(computedErrors)) {
		if (fieldsFilter.length === 0) {
			deepMappingError(computedErrors, errorsList, intl);
		} else {
			fieldsFilter.forEach(field => {
				deepMappingError(computedErrors[field], errorsList, intl);
			});
		}
	}

	errorsList = uniq(errorsList);

	const { track } = useAnalytics();

	const [errorHandled, setErrorHandled] = useState(false);

	useEffect(() => {
		if (isSubmitting && scrollable) {
			scrollToElement({ element: ref.current, offset: -100, disableSmoothScroll: false });
		}
	}, [isSubmitting, ref.current, scrollable]);

	const previousSubmitCount = usePrevious(submitCount);

	useEffect(() => {
		// isSubmitting pour éviter le double envoi de l'event si erreur au premier submit
		// errorHandled permet de s'assurer que l'on envoit bien un seul event à chaque submit
		// et non pas à chaque fois que le composant render
		if (!isEmpty(computedErrors) && !errorHandled && !isSubmitting) {
			onError();

			track("form_errors", {
				formErrors: Object.entries(computedErrors).map(entry => {
					const [key, value] = entry;
					const errorKey = value?.id;
					return {
						fieldName: key,
						errorKey,
						errorMsg: errorKey
							? intl.formatMessage({ id: errorKey })
							: "Unknow error message because errorKey is not an error id",
					};
				}),
			});

			setErrorHandled(true);
		}
	}, [computedErrors, isSubmitting, errorHandled]);

	useEffect(() => {
		if (submitCount && previousSubmitCount !== submitCount) {
			setErrorHandled(false);
		}
	}, [submitCount, previousSubmitCount]);

	if (!showError) {
		return false;
	}

	return (
		<div ref={ref} data-testid="global-error-message" className="form-error-messages">
			<AlertMessage
				{...errorsList.length > 0 && !disableErrorsDetails && { errors: errorsList }}
				message={message}
				alertType={alertType}
			/>
		</div>
	);
};

FormErrorMessages.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	fieldsFilter: PropTypes.array,
	disableErrorsDetails: PropTypes.bool,
	errors: PropTypes.object,
	isSubmitting: PropTypes.bool,
	onError: PropTypes.func,
	submitCount: PropTypes.number,
	scrollInToView: PropTypes.bool,
	alertType: alertTypeProptypes,
};

export default memo(FormErrorMessages);
