const IconNext = props => (
	<svg viewBox="0 0 175 255.54" {...props}>
		<path
			fill="currentColor"
			d="M148.42,127.06,46.64,25.32A11.43,11.43,0,1,0,30.46,41.47l93.7,93.67-93.7,93.66-.24.24A11.43,11.43,0,0,0,46.63,245L148.41,143.21A11.53,11.53,0,0,0,148.42,127.06Z"
		/>
	</svg>
);

export default IconNext;
