import { UPDATE_RESPONSIVE_STATE } from "app/actionTypes";

export const RESOLUTION = {
	XLARGE: "xlarge",
	LARGE: "large",
	MEDIUM: "medium",
	SMALL: "small",
	UNKNOWN: "unknown",
};

export default (resolution = RESOLUTION.UNKNOWN, action) => {
	switch (action.type) {
		case UPDATE_RESPONSIVE_STATE: {
			return action.resolution;
		}
		default:
			return resolution;
	}
};
