const SvgIconCb = props => (
	<svg x={0} y={0} viewBox="0 0 424 188" xmlSpace="preserve" {...props}>
		<g id="Calque_1_1_">
			<g id="XMLID_1_">
				<path
					d="M417.9 129.5c2.2 21.5-9.5 38.6-30.2 44.6-4.1 1.2-8.5 1.8-12.8 1.8-46.5.1-93 0-139.4.2-4.4 0-5.7-1.2-5.6-5.6.2-24 .2-48 0-72 0-4.4 1.2-5.7 5.6-5.6 24.3.2 48.6.1 73 .1 23.2 0 46.3.4 69.5-.1C397.7 92.4 415.9 109.5 417.9 129.5zM379.4 9c19 0 34.6 16.8 35.8 35.8 1.5 22.9-18.5 41-40.3 40.3-22.6-.7-45.3-.1-68-.1-23.8 0-47.6-.1-71.4.1-4.4 0-5.7-1.2-5.6-5.6.2-21.6.2-43.3 0-64.9 0-4.4 1.2-5.7 5.6-5.7C283.5 9 331.5 8.9 379.4 9zM218.8 58.9c2.3 7.7 3.1 15.6 3.9 23.5.4 3.6-2.4 2.6-4.1 2.6-12.8.1-25.7 0-38.5 0-3.7 0-7.3 0-11 0-16.3 0-32.6 0-49 0-2.4 0-5.1-.6-5.1 3.4s2.1 4.6 5.4 4.6c32.3-.1 64.6.1 97-.1 4.8 0 6.1 1.5 5.7 6-1.6 17.1-5.1 33.5-15 48-9.6 14-23.7 21.5-39.5 26.1-14.9 4.4-30.2 5.9-45.7 6.3-19.3.5-38.4-.3-57.1-5.2-32.3-8.6-48.4-27.6-54.8-61.4C8 96.8 8.7 81 11.6 65.2c4.6-25.2 19.6-41.5 43.6-50C71.3 9.5 87.9 7 104.9 5.9c21.3-1.4 42 .8 62.6 6.1 13.1 3.4 25.1 9.1 35 18.3C210.7 37.8 215.5 48 218.8 58.9z"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
);

export default SvgIconCb;
