import { useState, useEffect } from "react";

//@see https://stackoverflow.com/a/57991537
export default ({ ref }) => {
	const [isSticky, setIsSticky] = useState(false);
	let cachedRef, observer;

	useEffect(() => {
		if ("IntersectionObserver" in window && ref?.current) {
			cachedRef = ref.current;
			observer = new IntersectionObserver(
				// There are two cases of intersectionRatio < 1, to be sure sticking on top, we need limiting ClientRect.y < 1
				([e]) => setIsSticky(e.intersectionRatio < 1 && e?.boundingClientRect?.y < 1),
				{
					threshold: [1],
				}
			);
			observer.observe(cachedRef);
		}
		return () => {
			if (observer) {
				observer.unobserve(cachedRef);
			}
		};
	}, [ref?.current]); // without ref?.current here, this hook does not work on merchandising filters when going from listing to merch listing

	return isSticky;
};
