import { isObject } from "app/utils/utils";

export const isValidationMessage = error => {
	return Boolean(error.id);
};

/*
	For complicated forms, we need to pass deeply to generate errors.
	Ex:
	errors = {
		occupancies: [
			{
				"childrenBirthdates": [
					{
						"id": "error.form.generic",
						"description": "Error form generic",
						"defaultMessage": "Some field are required or invalid. Please check the form again."
					},
					{
						"id": "error.form.generic",
						"description": "Error form generic",
						"defaultMessage": "Some field are required or invalid. Please check the form again."
					}
				]
			},
			{
				"childrenBirthdates": [
					{
						"id": "error.form.generic",
						"description": "Error form generic",
						"defaultMessage": "Some field are required or invalid. Please check the form again."
					}
				]
			}
		]
	}
]
*/
export const deepMappingError = (errors, errorsList = [], intl) => {
	if (errors) {
		if (isValidationMessage(errors)) {
			const { id, values } = errors;
			errorsList.push(intl.formatMessage({ id }, { ...values }));
		} else if (Array.isArray(errors)) {
			errors.forEach(error => {
				deepMappingError(error, errorsList, intl);
			});
		} else if (isObject(errors)) {
			Object.keys(errors).forEach(key => {
				deepMappingError(errors[key], errorsList, intl);
			});
		}
	}
};

/*
	The getFieldErrorNames() function takes a Formik error as an argument,
	and returns an array of error field names using object dot-notation for array fields.
	For instance, given the error array from above, it'll return ['friends'],
	meaning that these attributes at that index have a validation error.
*/
export const getFieldErrorNames = formikErrors => {
	const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
		Object.keys(obj).forEach(key => {
			const value = obj[key];
			if (!value) {
				return;
			}

			const nextKey = prefix ? `${prefix}.${key}` : key;
			if (typeof value === "object" && !isValidationMessage(value)) {
				transformObjectToDotNotation(value, nextKey, result);
			} else {
				result.push(nextKey);
			}
		});

		return result;
	};

	return transformObjectToDotNotation(formikErrors);
};
