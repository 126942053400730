const IconPrevious = props => (
	<svg viewBox="0 0 175 255.54" {...props}>
		<path
			fill="currentColor"
			d="M30.3,143.21,132.08,245A11.43,11.43,0,0,0,148.49,229l-.24-.24-93.7-93.66,93.7-93.67a11.43,11.43,0,1,0-16.18-16.16L30.29,127.06A11.53,11.53,0,0,0,30.3,143.21Z"
		/>
	</svg>
);

export default IconPrevious;
