const IconCircleTick = props => (
	<svg viewBox="0 0 23.1 23.3" {...props}>
		<style>{".tick{fill:currentColor}"}</style>
		<path
			fill="none"
			stroke="currentColor"
			strokeMiterlimit={10}
			strokeWidth={1.421}
			d="M20.1,6c1.1,1.6,1.7,3.5,1.7,5.6c0,5.7-4.6,10.3-10.3,10.3S1.3,17.3,1.3,11.6S5.9,1.4,11.6,1.4c2.2,0,4.2,0.7,5.9,1.9"
		/>
		<path
			d="M10.6,17.4L10.6,17.4c-0.7,0.6-1.7,0.5-2.2-0.2l-3.6-4.4C4.2,12,4.4,11,5.1,10.5l0,0c0.7-0.6,1.7-0.5,2.2,0.2l3.6,4.4C11.5,15.8,11.3,16.8,10.6,17.4z"
			className="tick"
		/>
		<path
			d="M20.4,1.8C17.7,4.3,12,11.7,9.7,13.8l0,2.3l1,1.1c0,0,7.9-10.8,10.4-14.8C21.5,1.9,20.9,1.4,20.4,1.8z"
			className="tick"
		/>
	</svg>
);

export default IconCircleTick;
