const SvgIconRadioChecked = props => (
	<svg viewBox="-1 -1 22 22" {...props}>
		<path
			d="M10,1c4.9,0,9,4,9,9c0,4.9-4,9-9,9c-4.9,0-9-4-9-9C1.1,5,5.1,1,10,1 M10,0c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0L10,0z"
			fill="currentColor"
		/>
		<circle cx={10.1} cy={9.9} r={5} className="st2" fill="currentColor" />
	</svg>
);

export default SvgIconRadioChecked;
