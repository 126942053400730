const IconTickbox = props => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M0.5 0.5H13.5V13.5H0.5V0.5Z" fill="white" />
		<path d="M0.5 0.5H13.5V13.5H0.5V0.5Z" stroke="#BABABA" />
	</svg>
);

export default IconTickbox;
