const IconPaiement4x = props => (
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M20.3135 14.8658V13.7715H17.5066V13.6445L19.4414 8.98273H20.8386L19.253 12.7093H20.3153L20.3944 11.3929H21.6312V12.7093H22.3767V13.7715H21.6294V14.8658H20.3135Z"
			fill="currentColor"
		/>
		<path
			d="M28.353 9.04625L26.5137 11.8532L28.5129 14.8662H26.9113L25.8646 13.1375L25.6425 12.7253L25.4048 13.1375L24.323 14.8662H22.7236L24.801 11.7425L23.024 9.06269H24.5789L25.451 10.4422L25.6887 10.8544L25.9424 10.4262L26.7985 9.06224H28.3534L28.353 9.04625Z"
			fill="currentColor"
		/>
		<path
			d="M32 12.1384C31.9996 10.1335 31.3185 8.1881 30.0682 6.6208C28.8179 5.05351 27.0725 3.95718 25.1178 3.51132C23.1631 3.06546 21.115 3.2965 19.3087 4.1666C17.5025 5.03671 16.0452 6.49432 15.1754 8.30075H1.58561C1.16547 8.30204 0.762917 8.46951 0.465837 8.76659C0.168757 9.06367 0.00128894 9.46623 0 9.88636L0 24.3803C0.00128894 24.8004 0.168757 25.203 0.465837 25.5C0.762917 25.7971 1.16547 25.9646 1.58561 25.9659H25.0547C25.4748 25.9646 25.8774 25.7971 26.1745 25.5C26.4715 25.203 26.639 24.8004 26.6403 24.3803V20.273C28.2322 19.5911 29.5888 18.4566 30.5416 17.0105C31.4944 15.5643 32.0015 13.8702 32 12.1384V12.1384ZM14.557 10.045C14.3917 10.7359 14.3066 11.4435 14.3033 12.1539C14.3033 12.3601 14.3193 12.5502 14.3193 12.7408H1.74421V10.045H14.557ZM24.8961 24.2372H1.74421V15.9915H15.1754C15.8989 17.4926 17.0312 18.7591 18.4422 19.6455C19.8532 20.5319 21.4856 21.0022 23.1519 21.0025C23.7376 21.0009 24.3217 20.9425 24.8961 20.8279V24.2372ZM23.1519 19.5755C21.6825 19.5774 20.2455 19.1435 19.0227 18.3288C17.7998 17.514 16.8461 16.3548 16.2821 14.998C15.7181 13.6411 15.5691 12.1475 15.854 10.706C16.1389 9.26443 16.8449 7.93976 17.8827 6.89949C18.9204 5.85921 20.2434 5.15006 21.6843 4.86171C23.1251 4.57336 24.6191 4.71877 25.9773 5.27954C27.3355 5.84032 28.4969 6.79128 29.3146 8.01215C30.1323 9.23302 30.5696 10.669 30.5712 12.1384C30.5729 14.1082 29.7923 15.9982 28.401 17.3927C27.0098 18.7873 25.1218 19.5724 23.1519 19.5755V19.5755Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPaiement4x;
