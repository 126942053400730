const IconHelp = props => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.95 16C10.3 16 10.596 15.879 10.838 15.637C11.08 15.395 11.2007 15.0993 11.2 14.75C11.2 14.4 11.0793 14.104 10.838 13.862C10.5967 13.62 10.3007 13.4993 9.95 13.5C9.6 13.5 9.30433 13.621 9.063 13.863C8.82167 14.105 8.70067 14.4007 8.7 14.75C8.7 15.1 8.821 15.396 9.063 15.638C9.305 15.88 9.60067 16.0007 9.95 16ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788001C7.31667 0.263334 8.61667 0.000667143 10 4.76837e-07C11.3833 4.76837e-07 12.6833 0.262667 13.9 0.788001C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10.1 5.7C10.5167 5.7 10.8793 5.83333 11.188 6.1C11.4967 6.36667 11.6507 6.7 11.65 7.1C11.65 7.46667 11.5377 7.79167 11.313 8.075C11.0883 8.35833 10.834 8.625 10.55 8.875C10.1667 9.20833 9.82933 9.575 9.538 9.975C9.24667 10.375 9.10067 10.825 9.1 11.325C9.1 11.5583 9.18767 11.7543 9.363 11.913C9.53833 12.0717 9.74233 12.1507 9.975 12.15C10.225 12.15 10.4377 12.0667 10.613 11.9C10.7883 11.7333 10.9007 11.525 10.95 11.275C11.0167 10.925 11.1667 10.6123 11.4 10.337C11.6333 10.0617 11.8833 9.79933 12.15 9.55C12.5333 9.18333 12.8627 8.78333 13.138 8.35C13.4133 7.91667 13.5507 7.43333 13.55 6.9C13.55 6.05 13.204 5.35433 12.512 4.813C11.82 4.27167 11.016 4.00067 10.1 4C9.46667 4 8.86267 4.13333 8.288 4.4C7.71333 4.66667 7.27567 5.075 6.975 5.625C6.85833 5.825 6.82067 6.03767 6.862 6.263C6.90333 6.48833 7.016 6.659 7.2 6.775C7.43333 6.90833 7.675 6.95 7.925 6.9C8.175 6.85 8.38333 6.70833 8.55 6.475C8.73333 6.225 8.96267 6.03333 9.238 5.9C9.51333 5.76667 9.80067 5.7 10.1 5.7Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconHelp;
