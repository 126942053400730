const IconEngagementPaiementSecurise = props => (
	<svg viewBox="0 0 57.29 73.78" {...props}>
		<path
			fill="currentColor"
			d="M16.1,29.88c0,.08,0,.17,0,.27H40.74v-.27q0-4.7,0-9.4a12,12,0,0,0-.25-2.39A12.32,12.32,0,0,0,16.1,20.51Q16.1,25.19,16.1,29.88Zm8.63,24.6a4.18,4.18,0,0,0,2.45,2.35v.32q0,3.28,0,6.57a2.35,2.35,0,0,0,0,.44,1.3,1.3,0,0,0,.94,1.05,1.24,1.24,0,0,0,1.32-.36,1.34,1.34,0,0,0,.39-1q0-3.35,0-6.7v-.32a4.1,4.1,0,1,0-5.26-5A4,4,0,0,0,24.73,54.48Zm31.6-19.59a8,8,0,0,1,.75,3.55q0,13.59,0,27.17c0,.14,0,.28,0,.42a6.39,6.39,0,0,1-.17,1.45,8.19,8.19,0,0,1-6.71,6.31l-.56.1H7.33l-.17,0-.72-.11A8.18,8.18,0,0,1-.05,67c0-.28-.1-.56-.15-.84V37.87c0-.12.08-.25.1-.37S0,37,0,36.77a8.14,8.14,0,0,1,7.71-6.61l.27,0v-.37Q8,25.39,8,21a23.37,23.37,0,0,1,.14-2.59,20.31,20.31,0,0,1,1.12-4.75A20.58,20.58,0,0,1,27.44.16a2.82,2.82,0,0,0,.31,0h1.57a2.85,2.85,0,0,0,.31.05A21.11,21.11,0,0,1,33,.63,20.41,20.41,0,0,1,49,20.6q0,4.61,0,9.21c0,.11,0,.22,0,.34l.31,0c.29,0,.58,0,.86.07A8,8,0,0,1,56.33,34.89Z"
			transform="translate(.21 -.11)"
		/>
	</svg>
);

export default IconEngagementPaiementSecurise;
