const IconTickboxTick = props => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M0.5 0.5H13.5V13.5H0.5V0.5Z" fill="white" />
		<path d="M0.5 0.5H13.5V13.5H0.5V0.5Z" stroke="#BABABA" />
		<g clipPath="url(#clip0_3534_19751)">
			<path
				d="M6.37372 8.07643L4.50698 6.36525L3.98145 6.91453L6.37372 9.17498L9.98145 5.40425L9.45591 4.85498L6.37372 8.07643Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3534_19751">
				<rect width="6" height="6" fill="white" transform="translate(4 4)" />
			</clipPath>
		</defs>
	</svg>
);

export default IconTickboxTick;
