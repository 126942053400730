import PropTypes from "prop-types";
import { useEffect } from "react";
import { updateResponsiveState } from "app/pages/.shared/responsive/responsiveActionCreator";
import { connect } from "react-redux";
import { sendTagOnUnmatchedResolution } from "app/utils/analytics";
import { bindActionCreators } from "redux";
import useResponsiveState from "app/pages/.shared/responsive/useResponsiveState";

const ResponsiveStateProvider = ({ children, resolution, updateResponsiveState = () => {} }) => {
	const clientResolution = useResponsiveState(resolution);
	useEffect(() => {
		updateResponsiveState(clientResolution);
		if (clientResolution !== resolution) {
			sendTagOnUnmatchedResolution(clientResolution, resolution);
		}
	}, [clientResolution]);
	return children;
};

ResponsiveStateProvider.propTypes = {
	updateResponsiveState: PropTypes.func,
	resolution: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ updateResponsiveState }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResponsiveStateProvider);
