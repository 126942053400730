const IconArrowRight = props => (
	<svg
		width="8"
		height="14"
		viewBox="0 0 8 14"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="flip-if-rlt"
	>
		<path
			d="M0.0766602 12.4867L1.25666 13.6667L7.92333 7L1.25666 0.333328L0.0766602 1.51333L5.56333 7L0.0766602 12.4867Z"
			fill="currentColor"
		/>
	</svg>
);
export default IconArrowRight;
