const SvgIconMastercard = props => (
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.4379 27.8997V26.7134H14.374V26.1852H13.4379V25.3852H12.8519V26.1852H12.3091V26.7068H12.8519V27.8997C12.8519 29.1569 14.0876 28.9283 14.4662 28.6781L14.3089 28.1923C14.0386 28.3427 13.4379 28.5069 13.4379 27.8997Z"
			fill="currentColor"
		/>
		<path
			d="M18.5521 26.4998V26.1856H17.9661V28.8073H18.5521V27.3287C18.5323 27.2142 18.5465 27.0964 18.5927 26.9898C18.639 26.8832 18.7153 26.7924 18.8124 26.7286C18.9095 26.6648 19.0232 26.6307 19.1394 26.6305C19.2556 26.6304 19.3693 26.6641 19.4666 26.7277L19.6381 26.1848C19.4505 26.0923 19.235 26.0733 19.034 26.1316C18.833 26.1899 18.6611 26.3212 18.5521 26.4998V26.4998Z"
			fill="currentColor"
		/>
		<path
			d="M10.301 26.9282C10.301 26.521 11.1492 26.5854 11.623 26.8511L11.859 26.3884C11.1874 25.9528 9.69995 25.9597 9.69995 26.9744C9.69995 27.9961 11.3358 27.5674 11.3358 28.0458C11.3358 28.4965 10.3719 28.4603 9.85725 28.1029L9.60742 28.5536C10.4074 29.0964 11.9365 28.9823 11.9365 28.0177C11.9368 26.9424 10.301 27.4282 10.301 26.9282Z"
			fill="currentColor"
		/>
		<path
			d="M17.3808 27.7208C17.3808 26.5642 16.8522 26.1066 16.1378 26.1066C15.9689 26.1044 15.8012 26.1356 15.6444 26.1983C15.4875 26.2609 15.3445 26.3539 15.2236 26.4718C15.1026 26.5897 15.0061 26.7303 14.9395 26.8856C14.8729 27.0408 14.8376 27.2076 14.8354 27.3765C14.8354 27.4128 14.8355 27.4494 14.8389 27.4856C14.8389 28.9507 16.4532 29.1928 17.2535 28.5L16.9837 28.0713C16.4246 28.5285 15.5819 28.4857 15.4172 27.7216H17.3835L17.3808 27.7208ZM16.1154 26.634C16.2885 26.6257 16.4579 26.6863 16.5864 26.8026C16.715 26.9188 16.7922 27.0813 16.8013 27.2543H15.4369C15.4473 27.0831 15.524 26.9226 15.6506 26.8068C15.7772 26.691 15.9439 26.6291 16.1154 26.634V26.634Z"
			fill="currentColor"
		/>
		<path
			d="M9.88397 23.8346C11.8315 23.8357 13.7361 23.2617 15.3586 22.1845C14.1418 21.1851 13.1616 19.9284 12.4887 18.5049C11.8157 17.0813 11.4667 15.5263 11.4667 13.9517C11.4667 12.3771 11.8157 10.8221 12.4887 9.39854C13.1616 7.975 14.1418 6.71832 15.3586 5.71895C14.0741 4.86473 12.6076 4.32298 11.0762 4.13705C9.54489 3.95112 7.99129 4.12617 6.53972 4.64819C5.08815 5.17021 3.7789 6.02471 2.71671 7.14334C1.65453 8.26196 0.868887 9.61365 0.422643 11.0903C-0.0236008 12.5669 -0.118063 14.1275 0.14682 15.6471C0.411703 17.1668 1.02858 18.6034 1.94809 19.842C2.8676 21.0805 4.06422 22.0867 5.44223 22.78C6.82024 23.4733 8.34138 23.8345 9.88397 23.8346V23.8346Z"
			fill="currentColor"
		/>
		<path
			d="M16.002 21.7137C17.1767 20.7881 18.1262 19.6081 18.7791 18.2625C19.4319 16.9169 19.7711 15.4408 19.7711 13.9452C19.7711 12.4496 19.4319 10.9734 18.7791 9.62784C18.1262 8.28225 17.1767 7.10223 16.002 6.17657C14.8273 7.10223 13.8778 8.28225 13.2249 9.62784C12.5721 10.9734 12.2329 12.4496 12.2329 13.9452C12.2329 15.4408 12.5721 16.9169 13.2249 18.2625C13.8778 19.6081 14.8273 20.7881 16.002 21.7137V21.7137Z"
			fill="currentColor"
		/>
		<path
			d="M4.88746 26.1139C4.85407 26.1121 4.82062 26.1121 4.78723 26.1139C4.60755 26.1036 4.42836 26.141 4.26777 26.2223C4.10719 26.3035 3.97091 26.4257 3.87273 26.5765C3.78404 26.4268 3.6558 26.3044 3.50208 26.2228C3.34837 26.1412 3.17514 26.1035 3.00141 26.1139C2.85331 26.1114 2.70686 26.1453 2.57486 26.2125C2.44286 26.2797 2.32934 26.3782 2.24422 26.4994V26.1852H1.6582V28.8069H2.24422C2.24422 27.4575 2.06571 26.6479 2.88729 26.6479C3.61596 26.6479 3.47331 27.3765 3.47331 28.8069H4.03774C4.03774 27.4995 3.85924 26.6479 4.68082 26.6479C5.40948 26.6479 5.26684 27.3623 5.26684 28.8069H5.85285V27.1641H5.83781C5.85082 26.8989 5.75805 26.6393 5.57987 26.4424C5.4017 26.2455 5.15267 26.1274 4.88746 26.1139V26.1139Z"
			fill="currentColor"
		/>
		<path
			d="M8.4795 26.4998C8.37967 26.3751 8.25216 26.2753 8.10708 26.2084C7.96201 26.1415 7.80334 26.1092 7.64365 26.1143C7.2949 26.139 6.96849 26.295 6.73018 26.5509C6.49187 26.8067 6.35938 27.1433 6.35938 27.493C6.35938 27.8426 6.49187 28.1792 6.73018 28.4351C6.96849 28.6909 7.2949 28.8469 7.64365 28.8716C7.80376 28.8797 7.96344 28.8489 8.10902 28.7817C8.25459 28.7146 8.38172 28.6132 8.4795 28.4861V28.8138H9.04393V26.1852H8.4795V26.4998ZM8.49916 27.5508C8.48745 27.7162 8.42696 27.8744 8.32534 28.0054C8.22372 28.1364 8.08554 28.2343 7.92827 28.2867C7.771 28.3392 7.60169 28.3438 7.44179 28.3C7.28188 28.2563 7.13855 28.166 7.02992 28.0408C6.92129 27.9156 6.85225 27.7609 6.83151 27.5964C6.81078 27.4319 6.8393 27.265 6.91346 27.1167C6.98761 26.9684 7.10407 26.8455 7.24812 26.7634C7.39216 26.6813 7.55732 26.6438 7.72269 26.6556V26.6556C7.73425 26.6556 7.7462 26.6556 7.75738 26.6556C7.86098 26.6614 7.9624 26.6876 8.05586 26.7327C8.14932 26.7778 8.233 26.8408 8.30209 26.9182C8.37119 26.9956 8.42436 27.0859 8.45857 27.1838C8.49278 27.2818 8.50736 27.3855 8.50147 27.4891C8.50108 27.5115 8.50031 27.5311 8.49916 27.5508Z"
			fill="currentColor"
		/>
		<path
			d="M29.8815 26.5002C29.2955 25.7218 27.7456 26.1359 27.7456 27.4918C27.7456 28.8774 29.3529 29.2491 29.8815 28.4834V28.8138H30.4459V25.1208H29.8815V26.5002ZM29.9247 27.4918C29.9196 27.7016 29.832 27.901 29.6809 28.0467C29.5299 28.1924 29.3275 28.2727 29.1176 28.2702C28.9077 28.2676 28.7073 28.1825 28.5598 28.0332C28.4123 27.8839 28.3295 27.6824 28.3295 27.4725C28.3295 27.2627 28.4123 27.0612 28.5598 26.9119C28.7073 26.7626 28.9077 26.6775 29.1176 26.6749C29.3275 26.6724 29.5299 26.7527 29.6809 26.8984C29.832 27.0441 29.9196 27.2434 29.9247 27.4533C29.9247 27.4648 29.9247 27.4783 29.9247 27.4918Z"
			fill="currentColor"
		/>
		<path
			d="M30.9887 20.0845H31.0458V19.756H31.1834V19.6989H30.853V19.756H30.9887V20.0845Z"
			fill="currentColor"
		/>
		<path
			d="M31.2824 28.5987C31.2966 28.5828 31.3045 28.5622 31.3045 28.5409C31.3045 28.5195 31.2966 28.4989 31.2824 28.483C31.2612 28.483 31.2465 28.4618 31.2053 28.4618H31.0896V28.7117H31.1467V28.6114H31.1679L31.245 28.7117H31.302L31.2249 28.6187C31.2612 28.6203 31.2612 28.5987 31.2824 28.5987Z"
			fill="currentColor"
		/>
		<path
			d="M31.5897 19.6916L31.4741 19.9414L31.3584 19.6916H31.2585V20.0771H31.3156V19.7845L31.4313 20.0343H31.5084L31.6086 19.7845V20.0775H31.6857V19.692L31.5897 19.6916Z"
			fill="currentColor"
		/>
		<path
			d="M31.439 28.4857C31.4178 28.4645 31.4031 28.4287 31.3819 28.4086C31.3607 28.3886 31.3249 28.3728 31.3048 28.3516C31.269 28.3516 31.2277 28.3304 31.1892 28.3304C31.1563 28.3397 31.1228 28.3468 31.0889 28.3516C31.0608 28.3671 31.0349 28.3863 31.0118 28.4086C30.9972 28.4164 30.9845 28.4274 30.9746 28.4407C30.9647 28.4541 30.958 28.4694 30.9548 28.4857C30.9372 28.5216 30.9298 28.5616 30.9336 28.6014C30.9322 28.636 30.9395 28.6705 30.9548 28.7016C30.958 28.7179 30.9647 28.7333 30.9746 28.7466C30.9845 28.7599 30.9972 28.7709 31.0118 28.7787C31.0336 28.8025 31.0598 28.8219 31.0889 28.8358C31.1199 28.8516 31.1545 28.8589 31.1892 28.857C31.2289 28.8605 31.2689 28.8532 31.3048 28.8358C31.326 28.8146 31.3619 28.7999 31.3819 28.7787C31.402 28.7575 31.4178 28.7217 31.439 28.7016C31.4543 28.6706 31.4618 28.6363 31.4606 28.6018V28.6018C31.4642 28.5618 31.4567 28.5217 31.439 28.4857V28.4857ZM31.3823 28.6785C31.3679 28.6958 31.3558 28.715 31.3465 28.7356C31.3374 28.7473 31.3257 28.7567 31.3123 28.7629C31.2989 28.7692 31.2841 28.7721 31.2694 28.7714C31.2482 28.7926 31.2335 28.7926 31.1922 28.7926C31.165 28.793 31.1383 28.7857 31.1151 28.7714C31.0939 28.7714 31.0793 28.7502 31.0581 28.7356C31.0369 28.7143 31.0222 28.6997 31.0222 28.6785C31.0073 28.6556 30.9999 28.6287 31.001 28.6014C31.001 28.5655 31.001 28.5443 31.0222 28.5243C31.0256 28.4954 31.0382 28.4684 31.0581 28.4472C31.0754 28.4328 31.0946 28.4207 31.1151 28.4113C31.138 28.3963 31.165 28.3889 31.1922 28.3901C31.2281 28.3901 31.2493 28.3901 31.2694 28.4113C31.3052 28.4325 31.3264 28.4325 31.3465 28.4472C31.3665 28.4618 31.3603 28.4915 31.3819 28.5274C31.3819 28.5486 31.4031 28.5632 31.4031 28.6045C31.4039 28.6203 31.3823 28.6345 31.3823 28.6777V28.6785Z"
			fill="currentColor"
		/>
		<path
			d="M26.4814 26.4994V26.1852H25.917V28.8069H26.4814V27.3283C26.4631 27.2142 26.4782 27.0972 26.5248 26.9914C26.5714 26.8856 26.6476 26.7955 26.7442 26.732C26.8409 26.6685 26.9538 26.6343 27.0694 26.6334C27.185 26.6326 27.2984 26.6652 27.3959 26.7273L27.5671 26.1848C27.3787 26.0968 27.1648 26.0802 26.9651 26.138C26.7654 26.1959 26.5935 26.3243 26.4814 26.4994V26.4994Z"
			fill="currentColor"
		/>
		<path
			d="M30.3534 19.4079C31.6144 17.5061 32.1787 15.2267 31.9505 12.9563C31.7223 10.6859 30.7157 8.56442 29.1014 6.9517C27.4871 5.33898 25.3646 4.33438 23.094 4.10835C20.8234 3.88233 18.5446 4.44879 16.644 5.71163C21.7948 9.9483 21.8723 17.9632 16.644 22.1772C17.726 22.8956 18.9389 23.3939 20.2135 23.6435C21.488 23.8931 22.7993 23.8893 24.0723 23.6321C25.3454 23.3749 26.5553 22.8695 27.633 22.1447C28.7107 21.4199 29.6351 20.4899 30.3534 19.4079V19.4079Z"
			fill="currentColor"
		/>
		<path
			d="M21.8735 26.8915L22.1433 26.4289C21.3148 25.7785 19.8074 26.1359 19.8074 27.5003C19.8074 28.9144 21.4077 29.2005 22.1433 28.5717L21.8735 28.1091C21.2181 28.5744 20.3953 28.296 20.3949 27.4949C20.3953 26.6783 21.2227 26.4138 21.8735 26.8915Z"
			fill="currentColor"
		/>
		<path
			d="M24.5734 26.5002C23.9804 25.7145 22.4375 26.1575 22.4375 27.4918C22.4375 28.8636 24.0379 29.256 24.5734 28.4834V28.8138H25.1594V26.1852H24.573L24.5734 26.5002ZM24.6166 27.5277C24.6093 27.6929 24.5532 27.8522 24.4554 27.9856C24.3576 28.1189 24.2225 28.2203 24.0671 28.2768C23.9117 28.3334 23.743 28.3426 23.5824 28.3033C23.4217 28.264 23.2764 28.1779 23.1646 28.056C23.0529 27.9341 22.9798 27.7818 22.9546 27.6183C22.9295 27.4549 22.9533 27.2877 23.0232 27.1378C23.093 26.9879 23.2058 26.8621 23.3471 26.7762C23.4885 26.6904 23.6521 26.6484 23.8173 26.6556V26.6556C23.8362 26.6556 23.8559 26.6556 23.874 26.6556C24.0832 26.6675 24.279 26.762 24.4185 26.9183C24.5579 27.0746 24.6296 27.28 24.6177 27.4891C24.6177 27.5027 24.6175 27.5156 24.617 27.5277H24.6166Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgIconMastercard;
