import { forwardRef, useCallback, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import classNames from "classnames";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import { inputSanitize } from "app/utils/sanitize";
import "./Input.scss";

// forwardRef est nécessaire pour le fonctionnement de la lib react-phone-number-input
// @see https://github.com/catamphetamine/react-phone-number-input#inputcomponent
const InputFormik = forwardRef(
	(
		{
			id,
			type = "text",
			isRequired,
			disabled,
			loading,
			label,
			note,
			onFocus,
			className,
			maxLength,
			"data-testid": dataCy,
			shouldSanitize = true, // dans le component AddressAutocomplete on met shouldSanitize = false pour reutiliser onChange de autocomplete
			...restProps
		},
		ref
	) => {
		const [hasChanged, setHasChanged] = useState(false);
		const [field, meta, helpers] = useField(restProps);

		const inputClassName = classNames("control-group", {
			[className]: true,
			"control-group--required": isRequired,
			"control-group--touched": meta.touched || field.value || loading,
			"control-group--error": (meta.touched && meta.error) || meta.error,
			"control-group--disabled": disabled,
			"control-group--loading": loading,
		});

		// put the label on top when input is focused
		const setTouchedOnFocus = useCallback(event => {
			if (typeof onFocus === "function") {
				onFocus(event);
			}
			helpers.setTouched(true);
		}, []);

		// put the label back to input middle when input is blur without value
		const setUntouchedOnBlur = useCallback(event => {
			if (event.target.value === "") {
				helpers.setTouched(false);
			}
			setHasChanged(false);

			if (typeof restProps?.onBlur === "function") {
				restProps.onBlur();
			}
		}, []);

		const handleChange = useCallback(
			event => {
				const { value } = event.target;
				const { onChange } = restProps;

				const handleValueChange = value => {
					if (
						typeof maxLength !== "number" ||
						(maxLength && value?.length <= maxLength)
					) {
						helpers.setValue(inputSanitize(value));
					}
				};

				handleValueChange(value);

				if (!hasChanged && value !== "") {
					helpers.setError();
					setHasChanged(true);
				}

				if (onChange && typeof onChange === "function") {
					onChange(event);
				}
			},
			[maxLength, shouldSanitize, hasChanged]
		);

		// maxLength obligatoire pour que l'autofill de l'année expiration de la carte de crédit fonctionne
		return (
			<div className={inputClassName} data-testid={dataCy}>
				{loading ? (
					<div className="control-group__loader">
						<HorizontalDotLoader dotSize="3px" color="#3493dc" />
					</div>
				) : (
					<input
						ref={ref}
						type={type}
						id={id}
						disabled={disabled}
						{...field}
						maxLength={maxLength}
						{...restProps}
						onFocus={setTouchedOnFocus}
						onBlur={setUntouchedOnBlur}
						{...shouldSanitize && { onChange: handleChange }}
						className="control-group__input"
					/>
				)}
				<label htmlFor={id} className="control-group__label">
					{label}
				</label>
				{note && <div className="control-group__note"> {note}</div>}
			</div>
		);
	}
);

InputFormik.propTypes = {
	id: PropTypes.string.isRequired, // required for label
	name: PropTypes.string.isRequired, // required for Formik.useField()
	type: PropTypes.string,
	isRequired: PropTypes.bool,
	shouldSanitize: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onFocus: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	note: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	className: PropTypes.string,
	"data-testid": PropTypes.string,
	maxLength: PropTypes.number,
};

export default InputFormik;
